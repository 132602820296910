body {
    background-color: #f6f6f6 !important;
}
.subsite-header {
    background-color: #fcfeec;
    height: 45px;
    font-size: small;
}
.mdview h2 {
    scroll-margin-top: 120px;
}
.search-suggestions {
    background: white;
    position: fixed;
    top: 125px;
    left: 0;
    width: 100%;
    max-height: 600px;
    overflow-y: scroll;
    border-bottom: solid;
    z-index: 1;
}
.search-suggestions .nav-link.disabled {
    color: #6c757d;
    pointer-events: none;
    cursor: default;
}

.suggestion-button {
    padding: 5px 10px 5px !important;
}
.suggestion-button:focus {
    font-weight: bold;
}
.badge {
    padding: 0.25em 0.1em 0.25em 1em;
}

.posts ul {
    overflow: auto;
    /*height: 500px;*/
}
.posts li {
    padding-bottom: 10px;
    font-size: 9pt;
}
.posts li:hover {
    background-color: #f5f8fa;
}
.posts a {
    color: #557B74;
}
.posts a:hover {
    text-decoration: none;
}

.gramene-tool {

}